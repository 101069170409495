import React from "react"
import { Link } from "gatsby"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { TitleArrow } from "./arrow"

const ProjectList = ({ projects }) => {
  projects.sort((a, b) => a.frontmatter.index - b.frontmatter.index)
  return (
    <div className="mt-20 last:mb-24">
      {projects.map(project => {
        return <Project project={project} />
      })}
    </div>
  )
}

const Project = ({ project }) => {
  const img = getImage(project.frontmatter.coverImage)
  return (
    <article className="mb-12 sm:mb-20">
      <Link
        to={project.fields.slug}
        className="flex flex-wrap flex-row md:flex-nowrap group rounded-lg duration-300 hover:bg-bg_hover hover:bg-opacity-90 project-title"
      >
        <GatsbyImage
          className="md:flex-1 mr-0 md:mr-10 rounded-lg"
          image={img}
          alt="project.frontmatter.title"
          layout="fullWidth"
        />
        <div className="md:flex-1 ml-0 md:ml-10 mr-0 md:mr-5 mt-4 md:mt-0 flex flex-col justify-center">
          <h3 className="text-black font-medium text-xl sm:text-3xl leading-normal sm:leading-normal">
            {project.frontmatter.title} <TitleArrow />
          </h3>
          <h4 className="text-black opacity-50 font-normal text-base sm:text-xl mt-2 sm:mt-4">
            {project.frontmatter.subtitle}
          </h4>
        </div>
      </Link>
    </article>
  )
}

export default ProjectList
