import React from "react"

export const TitleArrow = () => {
  return (
    <svg
      className="title-arrow"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0002 0.333374L9.12016 2.21337L16.5602 9.66671H0.333496V12.3334H16.5602L9.12016 19.7867L11.0002 21.6667L21.6668 11L11.0002 0.333374Z"
        fill="black"
      />
    </svg>
  )
}
