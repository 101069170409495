import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ProjectList from "../components/project-list"

const ProjectTemplate = ({ data, pageContext, location }) => {
  const project = data.mdx
  const otherProjects = data.allMdx.nodes.filter(
    p => pageContext.slug !== p.fields.slug
  )
  const siteTitle = data.site.siteMetadata.title

  // const { previous, next } = pageContext
  // Used to use <body></body> for MDXRenderer, but its css pops up to the outermost body tag which is pretty weird
  const banner = getImage(project.frontmatter.bannerImage)
  return (
    <Layout location={location} title={siteTitle}>
      <GatsbyImage
        image={banner}
        alt="project.frontmatter.title"
        className="rounded-t-2xl"
      />
      <div>
        <Seo title={project.frontmatter.title} />
        <article>
          <header className="mt-16 mx-0 md:mx-14 lg:mx-28">
            <h1 className="text-2xl sm:text-3xl md:text-4xl text-black opacity-90 leading-normal sm:leading-normal md:leading-normal mb-8">
              {project.frontmatter.title}
            </h1>
            <small className="text-xl text-black opacity-50">
              {project.frontmatter.subtitle}
            </small>
          </header>
          <div className="prose md:prose-lg max-w-none mt-16 mx-0 md:mx-14 lg:mx-28">
            <MDXRenderer localImages={project.frontmatter.embeddedImagesLocal}>
              {project.body}
            </MDXRenderer>
          </div>
          <footer></footer>
        </article>
        <div className="mt-16 md:mt-24">
          <h2 className="text-black opacity-90 font-semibold text-2xl md:text-4xl">
            More projects
          </h2>
          <ProjectList projects={otherProjects} />
        </div>
      </div>
    </Layout>
  )
}

export default ProjectTemplate

// https://www.gatsbyjs.com/docs/working-with-images-in-markdown/
export const pageQuery = graphql`
  query ProjectBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title
        subtitle
        bannerImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allMdx {
      nodes {
        fields {
          slug
        }
        frontmatter {
          company
          index
          subtitle
          title
          coverImage {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
